<template>
  <div class="my-4" :class="$attrs.class">
    <Label v-if="label" :for="id || name" :required="required">{{ label }}</Label>
    <textarea
      :id="id || name"
      v-model="model"
      :name="name || id"
      class="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
      :placeholder="placeholder"
      :aria-describedby="(id || name) + 'Label'"
      :autocomplete="autocomplete"
      :disabled="disabled"
      :readonly="readonly"
      :required="required"
      :rows="rows"
    />
    <BaseErrorList :errors="errors" />
  </div>
</template>

<script>
import Label from "~/components/inputs/Label";
import { withModel } from "~/mixins/withModel";

export default {
  components: {
    Label,
  },

  mixins: [withModel],
  inheritAttrs: false,

  props: {
    label: String,
    placeholder: String,
    name: String,
    id: String,
    rows: [String, Number],
    disabled: Boolean,
    readonly: Boolean,
    required: Boolean,
    autocomplete: {
      type: String,
      default: "off",
    },

    errors: Array,
  },
};
</script>
