<template>
  <Dialog :title="title" :content="body" :cancel="cancel" :confirm="confirm" data-testid="confirm-delete-dialog" @close="(value) => $emit('close', value)" />
</template>

<script>
import Dialog from "~/components/dialogs/Dialog";

export default {
  components: { Dialog },
  props: {
    title: { type: String, required: true },
    body: { type: String, required: true },
    confirm: { type: String, default: "Slet" },
    cancel: { type: String, default: "Annuller" },
  },

  emits: ["close"],
};
</script>
