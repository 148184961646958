<template>
  <div class="flex grow items-baseline justify-between">
    <label
      :id="$props.for + 'Label'"
      class="block font-medium"
      :class="[size === 'sm' ? 'mb-0.5 text-xs' : 'mb-1 text-sm', disabled ? 'text-gray-400' : 'text-gray-700']"
      :for="$props.for"
    >
      <slot />
    </label>
    <span v-if="!required" :id="$props.for + 'Optional'" class="text-xs text-gray-400"> Valgfri </span>
  </div>
</template>

<script>
export default {
  props: {
    for: {
      type: String,
      required: true,
    },

    required: Boolean,
    size: {
      type: String,
      default: "md",
      validator(value) {
        return ["sm", "md", "lg"].includes(value);
      },
    },

    disabled: Boolean,
  },
};
</script>
