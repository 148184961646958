<template>
  <article class="card rounded-lg border border-gray-200 bg-white">
    <header v-if="title || $slots['headerLeft'] || $slots['headerRight']" class="flex items-center border-b border-gray-200 px-6 py-5" data-testid="card-header">
      <slot name="headerLeft">
        <h3 class="text-lg font-medium leading-6 text-gray-900" data-testid="card-title">
          {{ title }}
        </h3>
      </slot>
      <div class="ml-auto flex-shrink-0">
        <slot name="headerRight" />
      </div>
    </header>
    <div v-if="$slots.header" class="border-b border-gray-200 px-6 py-5" data-testid="card-header">
      <slot name="header" />
    </div>
    <div :class="[!noPadding && 'p-6']">
      <slot />
    </div>
  </article>
</template>

<script>
export default {
  props: {
    title: String,
    noPadding: Boolean,
  },
};
</script>
