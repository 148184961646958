<template>
  <div ref="root" :class="renderContext.includes('form') && 'my-4'">
    <Label v-if="label" :for="_id" :required="required">{{ label }}</Label>
    <Tippy
      ref="tippy"
      :options="{
        placement: 'bottom',
        arrow: false,
        trigger: 'manual',
        animation: 'grow',
        maxWidth: 'none',
        popperOptions: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 4],
              },
            },
          ],
        },
      }"
      :maxHeight="300"
      preventBlurOnMouseDown
      @show="isShowing = true"
      @hide="isShowing = false"
    >
      <template #trigger="{ controlId }">
        <div
          data-testid="wrapper"
          class="relative overflow-hidden rounded-md border border-gray-300 focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-500"
          :aria-controls="controlId"
          @click="
            () => {
              $refs.queryInput.focus();
              $refs.tippy.show();
            }
          "
        >
          <input
            :id="_id"
            ref="input"
            type="text"
            tabindex="-1"
            aria-hidden="true"
            class="absolute inset-0 border-0 border-none"
            :name="_name"
            :value="model"
            :required="required"
            data-testid="valueInput"
            @focus="$refs.queryInput.focus()"
          />
          <div class="relative z-10 -my-px flex flex-wrap items-center bg-white px-1">
            <Tag v-for="option in selectedOptions" :key="option.value" :color="option.color" class="m-1" @mousedown.prevent>
              <template #default>
                {{ option.label }}
              </template>
              <template #right>
                <button v-tooltip="'Slet'" type="button" tabindex="-1" class="ml-2 cursor-pointer text-gray-400" @click="toggleOption(option)">
                  <svgicon name="solid-x" class="h-4 w-4" />
                </button>
              </template>
            </Tag>
            <div class="relative mx-1 flex-grow">
              <input
                ref="queryInput"
                v-model="query"
                type="text"
                data-testid="queryInput"
                aria-haspopup="listbox"
                :aria-expanded="isShowing"
                :aria-labelledby="_id + 'Label'"
                :aria-controls="controlId"
                class="absolute inset-0 rounded border-none p-0 text-sm font-medium leading-none text-gray-900 focus:ring-0"
                :placeholder="placeholder"
                @blur="
                  () => {
                    $refs.tippy.hide();
                    query = '';
                  }
                "
                @keydown="handleKeydown"
              />
              <div class="text-sm leading-none" :class="[contextSize === 'sm' && 'h-6', contextSize === 'md' && 'h-8', contextSize === 'lg' && 'h-10']" aria-hidden="true">
                {{ query }}
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #content="{ controlId }">
        <ul
          :id="controlId"
          ref="optionsList"
          tabindex="-1"
          role="multilistbox"
          class="py-1 text-sm"
          :aria-activedescendant="_id + 'Option' + highlightedIndex"
          :style="{ width: maxWidth + 'px' }"
          data-testid="optionsList"
          @mouseleave="highlightedIndex = -1"
        >
          <li
            v-if="emptyText && (!queriedOptions || queriedOptions.length === 0)"
            class="relative cursor-default select-none py-2 pl-3 pr-12 text-center text-xs text-gray-400"
          >
            {{ emptyText }}
          </li>
          <li
            v-for="(option, index) in queriedOptions"
            :id="_id + 'Option' + index"
            :key="option.value"
            class="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900"
            :class="{
              'bg-gray-100': highlightedIndex === index,
            }"
            role="option"
            :aria-checked="model.includes(option.value).toString()"
            @mousedown.prevent
            @mousemove="highlightedIndex = index"
            @click="toggleOption(option)"
          >
            <span :class="`flex items-center truncate font-normal`"> <TagColor v-if="option.color" :color="option.color" class="mr-3" /> {{ option.label }} </span>
            <span v-show="model.includes(option.value)" class="absolute inset-y-0 right-0 flex items-center pr-4 text-blue-600">
              <svgicon name="solid-check" class="h-5 w-5" />
            </span>
          </li>
        </ul>
      </template>
    </Tippy>
    <BaseErrorList :errors="errors" />
  </div>
</template>

<script>
import Fuse from "fuse.js";
import Label from "~/components/inputs/Label";
import Tippy from "~/components/tippy/Tippy";
import { getScrollParent, isElementVisible } from "~/utils/DOM";
import FormInput from "~/components/form/FormInput";
import Tag from "~/components/Tag";
import TagColor from "~/components/tag/TagColor";
import { uniqBy } from "lodash";
import { withRenderContextMixin } from "~/mixins/withRenderContextMixin";

export default {
  name: "TagsInput",

  components: { Label, Tippy, Tag, TagColor },

  extends: FormInput,

  mixins: [withRenderContextMixin()],

  props: {
    label: String,
    required: Boolean,
    emptyText: {
      type: String,
      default: "Ingen valgmuligheder",
    },

    filterable: Boolean,
    placeholder: String,
    options: {
      type: Array,
      required: true,
    },

    labelKey: {
      type: String,
      default: "label",
    },

    valueKey: {
      type: String,
      default: "value",
    },

    // Optional options array that is used to display the selected options if those are not in options array.
    selected: {
      type: Array,
    },

    size: {
      type: String,
      default: null,
      validator(value) {
        return ["sm", "md", "lg"].includes(value);
      },
    },

    errors: Array,
  },

  data() {
    return {
      isShowing: false,
      query: "",
      highlightedIndex: -1,
      fuse: new Fuse([], {
        keys: ["label"],
        threshold: 0.3,
      }),

      maxWidth: null,
    };
  },

  computed: {
    selectedOptions() {
      const preSelected = this.selected || [];
      const selected = this.model.map((value) => this.mappedOptions.find((option) => option.value === value)).filter(Boolean);

      return uniqBy([...preSelected, ...selected], "value");
    },

    mappedOptions() {
      if (!this.options) return [];

      return this.options.map((option) => ({
        label: option[this.labelKey],
        value: option[this.valueKey],
        color: option.color,
      }));
    },

    queriedOptions() {
      if (this.query && this.fuse) {
        return this.fuse.search(this.query).map(({ item }) => item);
      }

      return this.mappedOptions;
    },

    _id() {
      return this.id || this.name;
    },

    _name() {
      return this.name || this.id;
    },

    contextSize() {
      if (this.size != null) {
        return this.size;
      }

      if (this.renderContext.includes("modal")) {
        return "lg";
      }

      if (this.renderContext.includes("table")) {
        return "sm";
      }

      return "md";
    },
  },

  watch: {
    mappedOptions: {
      deep: true,
      immediate: true,
      handler(options, oldOptions) {
        this.fuse.setCollection(options);

        // If the options change, we need to update the selected options to
        // those that are present in the new options.
        if (oldOptions && oldOptions.length > 0) {
          this.model = this.model.filter((value) => options.find((option) => option.value === value));
        }
      },
    },

    query() {
      this.highlightedIndex = 0;
    },

    model: {
      deep: true,
      handler() {
        this.$refs.tippy.reposition();
      },
    },
  },

  mounted() {
    this.maxWidth = this.$refs.root.offsetWidth;
  },

  methods: {
    toggleOption(option) {
      if (!option) return;

      if (this.model.includes(option.value)) {
        this.model = this.model.filter((value) => value !== option.value);
      } else {
        this.model = this.model.concat(option.value);
        this.query = "";
      }
    },

    incrementHighlightedIndex() {
      if (this.highlightedIndex < this.mappedOptions.length - 1) {
        this.highlightedIndex += 1;
      }

      this.scrollHighlightedIndexIntoView();
    },

    decrementHighlightedIndex() {
      if (this.highlightedIndex > 0) {
        this.highlightedIndex -= 1;
      }

      this.scrollHighlightedIndexIntoView();
    },

    scrollHighlightedIndexIntoView() {
      if (this.highlightedIndex > -1) {
        const el = this.$refs.optionsList.children[this.highlightedIndex];
        const parent = getScrollParent(el);

        if (!isElementVisible(parent, el)) {
          el.scrollIntoView();
        }
      }
    },

    handleKeydown(event) {
      if (event.key === "Backspace" && this.query === "" && this.model.length > 0) {
        this.toggleOption(this.mappedOptions.find((option) => option.value === this.model[this.model.length - 1]));
      } else if (!this.$refs.tippy.showing && [" ", "ArrowDown", "ArrowUp"].includes(event.key)) {
        this.$refs.tippy.show();
        event.preventDefault();
      } else if (event.key === "ArrowDown") {
        this.incrementHighlightedIndex();
        event.preventDefault();
      } else if (event.key === "ArrowUp") {
        this.decrementHighlightedIndex();
        event.preventDefault();
      } else if (event.key === "Escape" && this.$refs.tippy.showing) {
        this.$refs.tippy.hide();
        event.stopPropagation();
      } else if (event.key === "Enter" && this.$refs.tippy.showing) {
        this.toggleOption(this.queriedOptions[this.highlightedIndex]);
        event.preventDefault();
      } else {
        this.$refs.tippy.show();
      }
    },
  },
};
</script>
