<script setup>
import { cva } from "class-variance-authority";
import { cn } from "~/utils/cn";
import { computed, ref, useAttrs, watch } from "vue";
import { nanoid } from "nanoid";
import { onClickOutside, useVModel } from "@vueuse/core";
import { autoUpdate, flip, offset, size as floatingSize, useFloating } from "@floating-ui/vue";
import { useRenderContext } from "~/composables/useRenderContext";

const wrapperVariants = cva(
  "flex w-full items-center justify-between overflow-hidden rounded-md border text-sm text-gray-900 shadow-sm ring-1 ring-transparent disabled:text-gray-500 [&:has(:disabled)]:bg-gray-50",
  {
    variants: {
      size: {
        sm: "h-6",
        md: "h-8",
        lg: "h-10",
      },
      state: {
        default: "border-gray-300 bg-white focus-within:border-blue-500 focus-within:outline-0 focus-within:outline-blue-500 focus-within:ring-blue-500",
        active: "border-blue-500 outline-0 outline-blue-500 ring-blue-500",
      },
      validity: {
        error:
          "border-red-500 bg-red-50 focus-within:border-red-500 focus-within:outline-0 focus-within:outline-red-500 focus-within:ring-red-500 data-[state=open]:border-red-500 data-[state=open]:outline-red-500 data-[state=open]:ring-red-500",
      },
    },
    compoundVariants: [
      {
        state: "active",
        validity: "error",
        class: "border-red-500 outline-0 outline-red-500 ring-red-500",
      },
    ],
    defaultVariants: {
      state: "default",
      size: "md",
    },
  }
);

const inputVariants = cva("-my-px w-full border-0 bg-transparent text-sm focus:outline-0 focus:ring-0", {
  variants: {
    size: {
      sm: "px-2 py-0 leading-6",
      md: "px-2 py-0 leading-8",
      lg: "px-3 py-0 leading-10",
    },
  },
  defaultVariants: {
    size: "md",
  },
});

defineOptions({
  inheritAttrs: false,
});
const attrs = useAttrs();
const emits = defineEmits(["update:modelValue", "query"]);
const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: null,
  },
  label: {
    type: String,
    default: null,
  },
  options: {
    type: Array,
    default: () => [],
  },
  id: {
    type: String,
    default: null,
  },
  name: {
    type: String,
    default: null,
  },
  required: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: "",
  },
  errors: {
    type: Array,
    default: () => [],
  },
  loading: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: null,
    validator(value) {
      return ["sm", "md", "lg"].includes(value);
    },
  },
});

const query = ref("");
const model = useVModel(props, "modelValue", emits, {
  passive: true,
});
const selected = computed(() => props.options.find((option) => option.value === model.value));
watch(query, (value) => {
  if (selected.value?.label === value) {
    return;
  }

  emits("query", value);

  if (value) {
    isOpen.value = true;
  }
});
watch(selected, () => {
  if (selected.value) {
    query.value = selected.value.label;
  }
});

const renderContext = useRenderContext();
const id = computed(() => props.id || props.name || nanoid(8));
const name = computed(() => props.name ?? id.value);
const size = computed(() => {
  if (props.size != null) {
    return props.size;
  }

  if (renderContext.includes("modal")) {
    return "lg";
  }

  if (renderContext.includes("table")) {
    return "sm";
  }

  return "md";
});

const isOpen = ref(false);
const active = ref();

const anchor = ref(null);
const input = ref(null);
const floating = ref(null);
const teleportTarget = computed(() => anchor.value?.closest("#focus-trap") || document.body);
const minWidth = ref(0);
const { floatingStyles } = useFloating(anchor, floating, {
  placement: "bottom-start",
  whileElementsMounted: autoUpdate,
  size,
  middleware: [
    offset(4),
    flip(),
    floatingSize({
      apply({ availableWidth, availableHeight, elements }) {
        Object.assign(elements.floating.style, {
          maxWidth: `${availableWidth}px`,
          maxHeight: `${availableHeight}px`,
        });
      },
    }),
  ],
});
onClickOutside(floating, reset, {
  ignore: [anchor],
});

watch(isOpen, () => {
  minWidth.value = anchor.value.clientWidth + 2;
});

function focusFirstOption(event) {
  if (isOpen.value) {
    event.preventDefault();
    floating.value.querySelector(`input[id^=__${id.value}]`).focus();
  }
}

function closePopup() {
  isOpen.value = false;
  input.value.focus();
}

function reset() {
  query.value = "";
  isOpen.value = false;
}

function select(value) {
  model.value = value;
  closePopup();
}

function toggleIsOpen() {
  isOpen.value = !isOpen.value;
  input.value.focus();
}
</script>

<template>
  <div v-bind="attrs">
    <BaseLabel v-if="label" :for="`__${id}_query`" :required="required" :disabled="disabled" :size="size">
      {{ label }}
    </BaseLabel>
    <div
      ref="anchor"
      :class="
        wrapperVariants({
          size,
          validity: errors.length ? 'error' : 'valid',
          state: isOpen ? 'active' : 'default',
        })
      "
    >
      <input
        :id="`__${id}_query`"
        ref="input"
        v-model="query"
        autocomplete="off"
        role="combobox"
        aria-autocomplete="list"
        :aria-controls="`__${id}_options`"
        :aria-owns="`__${id}_options`"
        :aria-expanded="isOpen"
        :data-active-option="active ? `__${id}_${active}` : ''"
        :class="cn(inputVariants({ size }))"
        :placeholder="placeholder"
        :disabled="disabled"
        @keydown.down.exact="focusFirstOption"
        @keydown.alt.down.prevent="isOpen = true"
        @keydown.alt.up.prevent="isOpen = false"
        @keydown.escape.prevent="reset"
        @keydown.tab="reset"
      />
      <div :class="[size === 'sm' && 'mr-2', size === 'md' && 'mr-2', size === 'lg' && 'mr-3']">
        <BaseSpinner v-if="loading" class="text-gray-400" />
        <button v-else type="button" aria-label="Åbn valgmuligheder" tabindex="-1" :disabled="disabled" @click="toggleIsOpen">
          <Svgicon name="outline-selector" class="h-5 w-5" />
        </button>
      </div>
      <input :id="id" v-model="model" :name="name" type="text" class="pointer-events-none fixed opacity-0" tabindex="-1" aria-hidden="true" />
    </div>
    <BaseErrorList :errors="errors" />
    <Teleport :to="teleportTarget">
      <div
        v-if="isOpen && options.length"
        :id="`__${id}_options`"
        ref="floating"
        role="listbox"
        :aria-busy="loading"
        class="z-70 overflow-hidden rounded-md border bg-white py-1 shadow-lg focus:outline-none"
        :style="[floatingStyles, `min-width: ${minWidth}px`]"
      >
        <div
          v-for="option in options"
          :key="option.label.replace(/[^a-z0-9]/gi, '')"
          class="flex h-8 cursor-default items-center leading-8 text-gray-700 focus-within:bg-gray-100 focus-within:text-gray-900"
          :class="[size === 'lg' ? 'gap-3 px-3' : 'gap-2 px-2']"
          @mouseover="({ currentTarget }) => currentTarget.querySelector(`#__${id}_${option.value}`).focus()"
          @mouseup="select(option.value)"
        >
          <label :for="`__${id}_${option.value}`" class="flex-1 cursor-default text-sm">
            {{ option.label }}
          </label>
          <input
            :id="`__${id}_${option.value}`"
            :name="`__${id}`"
            type="radio"
            :value="option.value"
            class="pointer-events-none fixed opacity-0"
            @keydown.alt.up="closePopup"
            @keydown.escape.stop.prevent="closePopup"
            @keydown.tab.stop.prevent
            @focus="active = option.value"
            @keydown.enter="select(option.value)"
          />
        </div>
      </div>
    </Teleport>
  </div>
</template>
