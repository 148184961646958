<template>
  <div class="my-4" :class="$attrs.class">
    <DateInput v-bind="{ ...inputAttrs, ...$props }" v-model="model" />
  </div>
</template>

<script>
import DateInput from "~/components/inputs/DateInput";
import { withModel } from "~/mixins/withModel";

export default {
  components: { DateInput },

  mixins: [withModel],
  inheritAttrs: false,

  props: DateInput.props,

  computed: {
    inputAttrs() {
      const { class: className, ...attrs } = this.$attrs;

      return attrs;
    },
  },
};
</script>
