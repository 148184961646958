import { notify } from "@kyvg/vue3-notification";

let asyncId = 0;

// negative values for duration for vue-notification is infinite duration
const infiniteDuration = -1;

function info(title, text, options) {
  notify({ ...options, title, text, type: "info" });
}

function success(title, text, options) {
  notify({ ...options, title, text, type: "success" });
}

function error(title, text, options) {
  notify({
    ...options,
    title,
    text,
    type: "error",
    duration: options?.duration || 25000,
  });
}

function clear() {
  notify({ clean: true });
}

function async(title, text, options) {
  const id = asyncId.toString();

  notify({
    ...options,
    id,
    title,
    text,
    type: "async",
    duration: options?.duration || infiniteDuration,
  });

  asyncId += 1;

  return () => notify.close(id);
}

export const toast = {
  info,
  success,
  error,
  clear,
  async,
};
