import _dayjs, { isDayjs as _isDayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import weekOfYear from "dayjs/plugin/weekOfYear";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";

import "dayjs/locale/da";

_dayjs.extend(utc);
_dayjs.extend(timezone);
_dayjs.extend(quarterOfYear);
_dayjs.extend(weekOfYear);
_dayjs.extend(isBetween);
_dayjs.extend(isSameOrBefore);
_dayjs.extend(isSameOrAfter);
_dayjs.extend(customParseFormat);
_dayjs.extend(localeData);

_dayjs.locale("da");

_dayjs.tz.setDefault("Europe/Copenhagen");

export const dayjs = _dayjs;

/**
 * Check if the given input is a dayjs object.
 * @param {any} input
 * @returns {input is Dayjs}
 */
export function isDayjs(input) {
  return _isDayjs(input);
}

/**
 * Get a dayjs object in the Europe/Copenhagen timezone.
 * @typedef {import('dayjs').Dayjs} Dayjs
 * @param {Dayjs | Date | string} input
 */
export function tz(input) {
  if (isDayjs(input)) {
    return input.tz("Europe/Copenhagen");
  }

  if (typeof input === "string" && input.includes("+")) {
    return dayjs(input).tz("Europe/Copenhagen");
  }

  return dayjs.tz(input);
}

/**
 * Get an array of dayjs objects in the given range.
 * @typedef {import('dayjs').Dayjs} Dayjs
 * @param {Dayjs} start
 * @param {Dayjs} end
 * @param {string} step
 * @returns {Dayjs[]}
 */
export function range(start, end, step = "day") {
  const range = [];
  let current = start;
  while (current.isSameOrBefore(end, "day")) {
    range.push(current);
    current = current.add(1, step);
  }
  return range;
}

/**
 * Check if two ranges overlap each other.
 * @param {Dayjs[] | Date[]} range1
 * @param {Dayjs[]  | Date[]} range2
 * @returns {boolean}
 */
export function isRangesOverlapping(range1, range2) {
  return !(dayjs(range1[1]).isSameOrBefore(range2[0]) || dayjs(range1[0]).isSameOrAfter(range2[1]));
}

/**
 * Update the time of a dayjs object.
 * @param {Dayjs} date
 * @param {string} time in the format HH:mm:ss.SSS
 * @returns {Dayjs}
 */
export function setTime(date, time) {
  const [hour, minute, rest = ""] = time.split(":");
  const [second, millisecond] = rest.split(".");
  let result = dayjs(date);

  if (hour) {
    result = result.hour(parseInt(hour, 10));
  }

  if (minute) {
    result = result.minute(parseInt(minute, 10));
  }

  if (second) {
    result = result.second(parseInt(second, 10));
  }

  if (millisecond) {
    result = result.millisecond(parseInt(millisecond, 10));
  }

  return result;
}
