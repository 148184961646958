<template>
  <Dialog :title="title" :loading="loading" confirm="Fortsæt" data-testid="confirm-dialog" @close="onClose">
    <template #content>
      <div class="mt-6 space-y-3">
        <div class="relative flex items-start">
          <div class="flex h-5 items-center">
            <input
              id="this"
              v-model="actionContext"
              value="THIS"
              aria-describedby="this-description"
              name="plan"
              type="radio"
              checked
              class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
            />
          </div>
          <div class="ml-3 text-sm">
            <label for="this" class="text-gray-700">Denne begivenhed</label>
          </div>
        </div>

        <div class="relative flex items-start">
          <div class="flex h-5 items-center">
            <input
              id="this_and_coming"
              v-model="actionContext"
              value="THIS_AND_COMING"
              aria-describedby="this_and_coming-description"
              name="plan"
              type="radio"
              class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
            />
          </div>
          <div class="ml-3 text-sm">
            <label for="this_and_coming" class="text-gray-700"> Denne og efterfølgende begivenheder </label>
          </div>
        </div>

        <div class="relative flex items-start">
          <div class="flex h-5 items-center">
            <input
              id="all"
              v-model="actionContext"
              value="ALL"
              aria-describedby="all-description"
              name="plan"
              type="radio"
              class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
            />
          </div>
          <div class="ml-3 text-sm">
            <label for="all" class="text-gray-700">Alle begivenheder</label>
          </div>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import Dialog from "~/components/dialogs/Dialog";

export default {
  components: { Dialog },

  props: {
    title: {
      type: String,
    },

    loading: Boolean,
  },

  emits: ["close"],

  data() {
    return {
      actionContext: "THIS",
    };
  },

  methods: {
    onClose(accepted) {
      if (accepted) {
        this.$emit("close", this.actionContext);
      } else {
        this.$emit("close", false);
      }
    },
  },
};
</script>
