import { createStore } from "vuex";

import session from "./modules/session";
import student from "./modules/student";

export const store = createStore({
  modules: {
    session,
    student,
  },
});
