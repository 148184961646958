<script setup>
import { cva } from "class-variance-authority";
import { cn } from "~/utils/cn";

defineOptions({
  inheritAttrs: false,
});
defineProps({
  size: {
    type: String,
    validator: (v) => ["sm", "md", "lg"].includes(v),
  },
});

const variants = cva("animate-spin", {
  variants: {
    size: {
      sm: "h-4 w-4",
      md: "h-5 w-5",
      lg: "h-6 w-6",
    },
  },
  defaultVariants: {
    size: "md",
  },
});
</script>

<template>
  <svg v-bind="$attrs" :class="cn(variants({ size }), $attrs.class)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" data-testid="base-spinner">
    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" />
    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
  </svg>
</template>
