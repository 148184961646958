<script setup>
import { inject } from "vue";
import { actionsPortalInjectKey } from "~/components/base/Tabs/BaseTabs";

const actionsPortal = inject(actionsPortalInjectKey);
</script>

<template>
  <portal :to="actionsPortal">
    <slot />
  </portal>
</template>
