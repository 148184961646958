/**
 * A mixin that injects render context and optionally provides an additional render context to descendants.
 * @see useRenderContextMixin
 * @param {string=} providedRenderContext The render context, if any, to provide to descendants.
 */
export function withRenderContextMixin(providedRenderContext) {
  return {
    inject: {
      renderContext: {
        default: () => [],
      },
    },

    provide() {
      if (providedRenderContext) {
        return {
          renderContext: this.renderContext.concat(providedRenderContext),
        };
      }

      return {};
    },
  };
}
