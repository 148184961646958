/**
 * Hyphenates a CPR number.
 * @param {string|number} input
 */
export default function hyphenateCpr(input) {
  if (typeof input !== "number" && typeof input !== "string") {
    throw new Error(`Invalid type: ${typeof input}`);
  }

  const string = input.toString();

  if (string.match(/^\d{6}-\d{4}$/)) {
    return string;
  }

  if (string.length !== 10 || !string.match(/^\d+$/)) {
    throw new Error(`Invalid CPR format: ${input}`);
  }

  const start = string.slice(0, 6);
  const end = string.slice(6, 10);

  return `${start}-${end}`;
}
