<script>
export const registerPanelInjectKey = Symbol("registerPanel");
export const actionsPortalInjectKey = "BASE_TABS_ACTIONS_PORTAL";
</script>

<script setup>
import { computed, nextTick, provide, ref } from "vue";
import { PortalTarget } from "portal-vue";
import { TabsList, TabsRoot, TabsTrigger } from "radix-vue";
import { nanoid } from "nanoid";
import { BaseTabTriggerPill, BaseTabTriggerUnderline } from ".";

const panels = ref(new Map());
const activePanel = ref("");
const actionsPortal = nanoid(4);

const props = defineProps({
  look: {
    type: String,
    default: "pills",
    validator: (value) => ["pills", "underline"].includes(value),
  },
});

provide(registerPanelInjectKey, ({ id, label }) => {
  panels.value.set(id, label);

  nextTick(() => {
    if (!activePanel.value) {
      activePanel.value = id;
    }
  });
});
provide(actionsPortalInjectKey, actionsPortal);

const TabTriggerComponent = computed(() => (props.look === "pills" ? BaseTabTriggerPill : BaseTabTriggerUnderline));
</script>

<template>
  <TabsRoot v-model="activePanel">
    <TabsList class="flex w-full flex-wrap justify-between">
      <BaseTabTriggerList :look="look">
        <TabsTrigger v-for="([id, label], index) in panels" :key="id" :value="id" asChild>
          <TabTriggerComponent :class="[index === panels.size - 1 && '!mr-auto']">
            {{ label }}
          </TabTriggerComponent>
        </TabsTrigger>
        <div class="!ml-0 flex">
          <PortalTarget :name="actionsPortal" />
        </div>
      </BaseTabTriggerList>
      <div class="flex-1">
        <slot name="afterHeader" />
      </div>
    </TabsList>
    <slot />
  </TabsRoot>
</template>
