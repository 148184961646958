<template>
  <Dialog :title="title" :content="body" :cancel="cancel" :confirm="confirm" :loading="loading" data-testid="confirm-dialog" @close="(value) => $emit('close', value)" />
</template>

<script>
import Dialog from "~/components/dialogs/Dialog";

export default {
  components: { Dialog },

  props: {
    title: { type: String, required: true },
    body: String,
    confirm: { type: String, default: "Okay" },
    cancel: { type: String, default: "Annuller" },
    loading: Boolean,
  },

  emits: ["close"],
};
</script>
