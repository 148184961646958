<script setup>
import { cva } from "class-variance-authority";
import { computed } from "vue";
import { cn } from "~/utils/cn";

defineOptions({
  inheritAttrs: false,
});

const props = defineProps({
  intent: {
    type: String,
    default: "default",
    validator: (v) => ["default", "success", "error", "warning"].includes(v),
  },
  size: {
    type: String,
    default: "sm",
    validator: (v) => ["xs", "sm", "md", "lg"].includes(v),
  },
  text: {
    type: String,
    required: true,
  },
});

const variants = cva("inline-flex items-center rounded", {
  variants: {
    intent: {
      default: "bg-gray-100 text-gray-800",
      success: "bg-green-100 text-green-800",
      error: "bg-red-100 text-red-800",
      warning: "bg-amber-100 text-amber-800",
    },
    size: {
      xs: "h-5 px-1 py-0 text-xs font-medium leading-5",
      sm: "h-6 px-1 py-0 text-sm leading-6",
      md: "h-8 px-2 py-0 text-sm leading-8",
      lg: "h-10 px-2 py-0 text-sm leading-10",
    },
  },
  defaultVariants: {
    intent: "default",
    size: "sm",
  },
});

const icon = computed(() => {
  const style = props.size === "xs" ? "solid" : "outline";

  switch (props.intent) {
    case "success":
      return style + "-check-circle";
    case "error":
      return style + "-exclamation-circle";
    case "warning":
      return style + "-exclamation";
    default:
      return null;
  }
});
</script>

<template>
  <span v-bind="$attrs" :class="cn(variants({ intent, size }), $attrs.class ?? '')">
    <svgicon v-if="icon" :name="icon" class="h-4 w-4" />
    <span v-if="text" class="px-1">{{ text }}</span>
  </span>
</template>
