<template>
  <ul v-if="warnings.length > 0">
    <li v-for="(error, index) in warnings" :key="index" class="p-2 text-xs text-amber-700">
      {{ error }}
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    warnings: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
