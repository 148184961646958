<script setup>
import { computed } from "vue";

const props = defineProps({
  for: {
    type: String,
    required: true,
  },
  required: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: "md",
    validator(value) {
      return ["sm", "md", "lg"].includes(value);
    },
  },
});

const targetId = computed(() => props.for);
const labelId = computed(() => targetId.value + "Label");
const descriptionId = computed(() => targetId.value + "Optional");
</script>

<template>
  <div class="flex grow items-baseline justify-between">
    <label
      :id="labelId"
      :for="targetId"
      class="block font-medium"
      :class="[size === 'sm' ? 'mb-0.5 text-xs' : 'mb-1 text-sm', disabled ? 'text-gray-400' : 'text-gray-700']"
    >
      <slot />
    </label>
    <span v-if="!required" :id="descriptionId" class="text-xs text-gray-400"> Valgfri </span>
  </div>
</template>
