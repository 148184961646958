<script>
import { withModel } from "~/mixins/withModel";

/**
 * This component is meant to be extended by components that represent different form inputs.
 */
export default {
  name: "FormInput",

  mixins: [withModel],

  props: {
    id: String,
    name: String,
    autofocus: Boolean,
  },

  created() {
    this.validateNameOrId();
  },

  mounted() {
    if (this.autofocus) {
      this.focus();
    }
  },

  methods: {
    validateNameOrId() {
      if (!this.name && !this.id) {
        throw new Error("Either name or id is required");
      }
    },

    focus() {
      const target = this.$refs.input || this.$el.querySelector("[name]");

      target.focus();
    },
  },
};
</script>
