import mitt from "mitt";

export const modalEvents = mitt();

export function openModal(nameOrOptions, propsMaybe) {
  const name = nameOrOptions.name || nameOrOptions;
  const props = nameOrOptions.props || propsMaybe;

  return new Promise((resolve) => {
    modalEvents.emit("open", {
      name,
      props,
      resolve,
    });
  });
}

export function closeModal(data) {
  modalEvents.emit("close", data);
}
