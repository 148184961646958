<template>
  <ul v-if="errors.length > 0" :class="$attrs.class">
    <li v-for="(error, index) in errors" :key="index" class="p-2 text-xs text-red-600">
      {{ error }}
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
