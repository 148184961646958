import { handleError } from "~/utils/handleError";

export const errorHandler = {
  install(app) {
    app.config.globalProperties.$errorHandler = {
      handleError,
    };
    app.config.errorHandler = function (error) {
      if (
        error.message.includes("Failed to fetch dynamically imported module") ||
        error.message.includes("error loading dynamically imported module") ||
        error.message.includes("Importing a module script failed") ||
        error.message.includes("'text/html' is not a valid JavaScript MIME type")
      ) {
        if (window.localStorage) {
          if (!localStorage.getItem("reloaded")) {
            localStorage["reloaded"] = true;
            window.location.reload();
          } else {
            localStorage.removeItem("reloaded");
          }
        } else {
          if (!window.location.hash) {
            window.location = window.location + "#reloaded";
            window.location.reload();
          }
        }
      }
    };
  },
};
