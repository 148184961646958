<script setup>
import { useVModel } from "@vueuse/core";
import { cva } from "class-variance-authority";
import { nanoid } from "nanoid";
import { computed, inject, provide, useAttrs } from "vue";
import { cn } from "~/utils/cn";

defineOptions({
  inheritAttrs: false,
});

const emit = defineEmits(["update:modelValue", "blur"]);
const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: null,
  },
  type: {
    type: String,
    default: "text",
    validator: (v) => ["text", "number", "password", "email", "time", "datetime-local"].includes(v),
  },
  label: {
    type: String,
    default: null,
  },
  required: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  errors: {
    type: Array,
    default: null,
  },
  size: {
    type: String,
    validator: (v) => ["sm", "md", "lg"].includes(v),
  },
  controls: {
    type: Boolean,
    default: true,
  },
});

const wrapperVariants = cva("flex w-full overflow-hidden rounded-md border shadow-sm ring-1 ring-transparent [&:has(:disabled)]:bg-gray-50", {
  variants: {
    size: {
      sm: "h-6",
      md: "h-8",
      lg: "h-10",
    },
    state: {
      default: "border-gray-300 bg-white text-gray-900 focus-within:border-blue-500 focus-within:ring-blue-500",
      error: "border-red-500 bg-red-50 text-gray-900 focus-within:border-red-500 focus-within:ring-red-500",
    },
  },
  defaultVariants: {
    state: "default",
    size: "md",
  },
});
const inputVariants = cva("-my-px w-full border-0 bg-transparent text-sm focus:outline-0 focus:ring-0", {
  variants: {
    size: {
      sm: "px-2 py-0 leading-6",
      md: "px-2 py-0 leading-8",
      lg: "px-3 py-0 leading-10",
    },
  },
  defaultVariants: {
    size: "md",
  },
});

const modelValue = useVModel(props, "modelValue", emit, {
  passive: true,
});

// Can include form, table, modal
const renderContext = inject("renderContext", []);
const size = computed(() => {
  if (props.size != null) {
    return props.size;
  }

  if (renderContext.includes("modal")) {
    return "lg";
  }

  if (renderContext.includes("table")) {
    return "sm";
  }

  return "md";
});
provide("INPUT_CONTEXT", {
  size: size,
});

const { class: rootClass, ...inputAttrs } = useAttrs();
const id = computed(() => inputAttrs.id || inputAttrs.name || nanoid());
const name = computed(() => inputAttrs.name ?? id.value);
</script>

<template>
  <div :class="cn(renderContext.includes('form') && 'my-4', rootClass)">
    <BaseLabel v-if="label" :for="id" :required="required" :disabled="disabled" :size="size">
      {{ label }}
    </BaseLabel>
    <div class="flex w-full" :class="wrapperVariants({ size, state: errors?.length ? 'error' : 'default' })">
      <slot name="prefix" />
      <input
        v-bind="inputAttrs"
        :id="id"
        v-model="modelValue"
        :type="type"
        :name="name"
        :class="cn(inputVariants({ size }), controls === false && 'controls-none')"
        :required="required"
        :disabled="disabled"
        :aria-labelledby="label ? id + 'Label' : null"
        @blur="$emit('blur', $event)"
      />
      <slot name="suffix" />
    </div>
    <BaseErrorList v-if="errors" :errors="errors" />
  </div>
</template>

<style scoped>
input.controls-none::-webkit-outer-spin-button,
input.controls-none::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.controls-none[type="number"] {
  -moz-appearance: textfield;
}
</style>
