<template>
  <FocusTrap active :returnFocusOnDeactivate="false">
    <div data-testid="confirm-dialog" class="fixed inset-x-0 bottom-0 z-100 overflow-y-scroll px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">
      <div class="transform overflow-hidden rounded-lg bg-white shadow-xl transition-all sm:w-full sm:max-w-lg">
        <div class="bg-white p-6 px-4">
          <div class="flex items-start">
            <div class="ml-4 mt-0 text-left">
              <h3 class="text-lg font-medium leading-6 text-gray-900">Registrer denne og resten af dagen</h3>
              <div class="mt-2">
                <p class="text-sm leading-5 text-gray-500">Dette vil overskrive den aktuelle registrering og efterfølgende på dagen.</p>
                <select
                  id="absenceType"
                  v-model="selectedType"
                  name="absenceType"
                  class="mt-4 block w-full max-w-xs rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                >
                  <option v-for="type in types" :key="type.value" :value="type.value">
                    {{ type.label }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-end bg-gray-50 px-6 py-3">
          <BaseButton @click="$emit('close', null)"> Annuller </BaseButton>
          <BaseButton primary class="ml-4" @click="$emit('close', selectedType)"> Fortsæt </BaseButton>
        </div>
      </div>
    </div>
  </FocusTrap>
</template>

<script>
import { FocusTrap } from "focus-trap-vue";

export default {
  components: {
    FocusTrap,
  },

  props: {
    types: {
      type: Array,
      required: true,
    },
  },

  emits: ["close"],

  data() {
    return {
      selectedType: this.types[0].value,
    };
  },
};
</script>
