<script>
import { defineAsyncComponent } from "vue";

export default {
  props: {
    name: {
      type: String,
      required: true,
    },

    strokeWidth: {
      type: Number,
      default: 2,
    },

    fill: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    dynamicComponent() {
      const { name } = this;

      return defineAsyncComponent(() => import(`../../icons/${name}.vue`));
    },
  },
};
</script>

<template>
  <component :is="dynamicComponent" :strokeWidth="strokeWidth" :class="fill || name.includes('solid') ? 'svg-fill' : ''" class="svg-icon" />
</template>
