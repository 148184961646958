<template>
  <FocusTrap>
    <div class="fixed inset-x-0 bottom-0 z-100 overflow-y-scroll px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center" v-bind="$attrs">
      <div class="transform overflow-hidden rounded-lg bg-white shadow-xl transition-all sm:w-full sm:max-w-md">
        <div class="flex items-start bg-white px-8 py-6">
          <div v-if="intent === 'danger'" class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <svg class="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
              />
            </svg>
          </div>
          <div class="flex-1">
            <div class="mt-0 w-full text-left">
              <slot name="header">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  {{ title }}
                </h3>
              </slot>
              <div class="mt-4">
                <slot name="content">
                  <p class="text-sm text-gray-700">
                    {{ content }}
                  </p>
                </slot>
              </div>
            </div>
            <div class="mt-6 flex justify-end space-x-2">
              <slot name="buttons">
                <BaseButton size="lg" :disabled="loading" @click="$emit('close', false)">
                  {{ cancel }}
                </BaseButton>
                <BaseButton :danger="intent === 'danger'" :primary="intent !== 'danger'" size="lg" :loading="loading" @click="$emit('close', true)">
                  {{ confirm }}
                </BaseButton>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </FocusTrap>
</template>

<script>
import { FocusTrap } from "focus-trap-vue";

export default {
  components: { FocusTrap },
  inheritAttrs: false,

  props: {
    intent: {
      type: String,
      default: "info",
      validator(value) {
        return ["info", "danger"].includes(value);
      },
    },

    title: { type: String },
    content: { type: String },
    confirm: { type: String, default: "Okay" },
    cancel: { type: String, default: "Annuller" },
    loading: { type: Boolean },
  },

  emits: ["close"],

  mounted() {
    window.addEventListener("keydown", this.handleKeydown);
  },

  beforeUnmount() {
    window.removeEventListener("keydown", this.handleKeydown);
  },

  methods: {
    handleKeydown(event) {
      if (!this.loading && event.key === "Escape") {
        this.$emit("close", false);
      }
    },
  },
};
</script>
