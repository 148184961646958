import { dayjs } from "~/utils/dayjs";

/**
 * Formats a date to dddd, DD/MM/YY
 *
 * @param {*} value expressions value (result of former chain)
 */
export default function formatDateWithDay(value, format) {
  const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || "";

  return value ? capitalize(dayjs(value, format).format("dddd, DD/MM/YYYY")) : "";
}
