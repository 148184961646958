import { tz } from "~/utils/dayjs";

/**
 * Formats a ISO datetime string to HH:mm
 *
 * @param {*} value ISO datetime string.
 */
export default function formatTime(value) {
  const format = value.length <= 8 ? ["H:m"] : undefined;
  return value ? tz(value, format).format("HH.mm") : "";
}
