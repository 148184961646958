export const withModel = {
  props: {
    modelValue: [String, Number, Boolean, Array, Object],
  },

  emits: ["update:modelValue", "input", "change"],

  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        if (this.modelValue === value) return;

        this.$emit("update:modelValue", value);
        this.$emit("input", value);
        this.$emit("change", value);
      },
    },
  },
};
