<template>
  <div ref="root" :data-testid="idOrName" :class="renderContext.includes('form') && 'my-4'">
    <Label v-if="label" :for="idOrName" :required="required">{{ label }}</Label>
    <Tippy
      ref="tippy"
      :options="{
        placement: 'bottom-start',
        arrow: false,
        trigger: 'manual',
        maxWidth: 'none',
        hideOnClick: false,
        popperOptions: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 4],
              },
            },
          ],
        },
      }"
      preventBlurOnMouseDown
      @show="isShowing = true"
      @hide="isShowing = false"
    >
      <template #trigger="{ controlId }">
        <div
          class="relative flex overflow-hidden rounded-md border border-gray-300 shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500"
          :class="[contextSize === 'sm' && 'h-6', contextSize === 'md' && 'h-8', contextSize === 'lg' && 'h-10']"
        >
          <input
            :id="idOrName"
            ref="input"
            :value="model"
            :required="required"
            tabindex="-1"
            class="absolute inset-0 focus:outline-none"
            aria-hidden="true"
            :name="nameOrId"
            @keydown.enter="toggleOptionsAndFocusTrigger"
            @keydown.space="toggleOptionsAndFocusTrigger"
          />

          <button
            ref="trigger"
            type="button"
            class="relative h-full w-full cursor-default bg-white text-left text-sm focus:outline-none"
            :class="[contextSize === 'sm' && 'pl-2 pr-10 leading-6', contextSize === 'md' && 'pl-2 pr-8 leading-8', contextSize === 'lg' && 'pl-3 pr-10 leading-10']"
            aria-haspopup="listbox"
            :aria-expanded="isShowing"
            :aria-controls="controlId"
            data-testid="trigger"
            @blur="closeOptions"
            @click="toggleOptions"
            @keydown="handleFindOption"
            @keydown.enter="handleEnterKeyPress"
            @keydown.esc="closeOptions"
            @keydown.down.prevent="highlightNextOption"
            @keydown.up.prevent="highlightPreviousOption"
          >
            <slot v-if="selected.length" name="selected" :options="selected">
              <span class="block truncate font-normal">
                {{ model.length === justOptions.length ? `Alle ${allLabel} (${model.length})` : `${selected.length} ud af ${justOptions.length} ${allLabel}` }}
              </span>
            </slot>
            <span v-else class="block truncate text-gray-900"> {{ placeholder }} </span>
            <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 text-gray-900">
              <svgicon name="outline-selector" :fill="false" class="h-5 w-5" />
            </span>
          </button>
        </div>
      </template>
      <template #content="{ controlId }">
        <ul
          :id="controlId"
          ref="listbox"
          class="p-1 text-sm"
          tabindex="-1"
          role="listbox"
          :aria-activedescendant="idOrName + 'Option' + highlightedOption?.value"
          :style="{ minWidth: maxWidth + 'px' }"
          @mouseleave="highlightedOption = null"
          @mousedown.prevent
        >
          <li v-if="emptyText && (!flatOptions || flatOptions.length === 0)" class="relative cursor-default select-none py-2 pl-3 pr-12 text-center text-xs text-gray-400">
            {{ emptyText }}
          </li>
          <li v-if="toggleAll">
            <button type="button" class="w-full rounded px-3 py-2 text-left font-medium text-blue-600 hover:bg-blue-50" @click="toggleSelectAll">
              {{ allSelected ? "Fravælg" : "Vælg" }} alle
            </button>
          </li>
          <li
            v-for="option in flatOptions"
            :id="!option.group && idOrName + 'Option' + option.value"
            :key="option.value"
            ref="option"
            class="relative cursor-default select-none rounded py-2 pl-3 pr-12"
            :class="{
              'bg-blue-600 text-white': highlightedOption === option,
              'text-gray-500': option.group,
              'text-gray-900': !option.group,
            }"
            :role="!option.group && 'option'"
            :aria-selected="!option.group && model.includes(option.value).toString()"
            @mousemove="handleMouseHoverOption($event, option)"
            @click="($event) => !option.group && toggleOption($event, option)"
          >
            <slot name="option" :option="option">
              <span class="block truncate" :class="{ 'text-xs font-medium': option.group }">
                {{ option.label }}
              </span>
            </slot>
            <span v-show="model.includes(option.value)" class="absolute inset-y-0 right-0 flex items-center pr-4">
              <svgicon name="solid-check" class="h-5 w-5" />
            </span>
          </li>
        </ul>
      </template>
    </Tippy>
    <BaseErrorList :errors="errors" />
  </div>
</template>

<script>
import Tippy from "~/components/tippy/Tippy";
import Label from "~/components/inputs/Label";
import FormInput from "~/components/form/FormInput";
import { withModel } from "~/mixins/withModel";
import { withRenderContextMixin } from "~/mixins/withRenderContextMixin";

export default {
  components: { Tippy, Label },

  extends: FormInput,

  mixins: [withModel, withRenderContextMixin()],

  props: {
    label: String,
    id: String,
    name: String,
    required: Boolean,
    emptyText: {
      type: String,
      default: "Ingen valgmuligheder",
    },

    autofocus: Boolean,
    // we can do groups by passing an array as the value like { label: 'My group', value: [...someOptions] }
    options: {
      type: Array,
      required: true,
    },

    errors: Array,
    placeholder: {
      type: String,
      default: "Vælg",
    },

    size: {
      type: String,
      default: null,
      validator(value) {
        return ["sm", "md", "lg"].includes(value);
      },
    },

    toggleAll: {
      type: Boolean,
      default: false,
    },

    allLabel: {
      type: String,
      default: "valgt",
    },
  },

  data() {
    return {
      isShowing: false,
      maxWidth: 0,
      highlightedOption: null,
      query: "",
    };
  },

  computed: {
    // options flattened to a single level with groups headings
    flatOptions() {
      return this.options;
      // TODO: fix groups
      // return this.options.reduce((acc, option) => {
      //   if (Array.isArray(option.value)) {
      //     return [...acc, { label: option.label, group: true }, ...option.value];
      //   }

      //   return [...acc, option];
      // }, []);
    },

    // same as flatOptions but only selectable options (no group headings)
    justOptions() {
      return this.flatOptions.filter((option) => !option.group);
    },

    selected() {
      return this.justOptions.filter((option) => this.model.includes(option.value));
    },

    idOrName() {
      return this.id || this.name;
    },

    nameOrId() {
      return this.name || this.id;
    },

    allSelected() {
      return this.flatOptions.length === this.selected.length;
    },

    contextSize() {
      if (this.size != null) {
        return this.size;
      }

      if (this.renderContext.includes("modal")) {
        return "lg";
      }

      if (this.renderContext.includes("table")) {
        return "sm";
      }

      return "md";
    },
  },

  watch: {
    model() {
      this.updateValidity();
      this.query = this.selected?.label || "";
    },

    options() {
      this.updateValidity();
    },

    selected() {
      this.setDefaultHighlightedOption();
    },

    isShowing() {
      this.setDefaultHighlightedOption();
      this.scrollToHighlightedOption();
    },
  },

  mounted() {
    this.maxWidth = this.$refs.root.offsetWidth;

    this.updateValidity();

    if (this.autofocus) {
      this.$refs.trigger.focus();
    }
  },

  methods: {
    toggleOption(event, option) {
      let newValue;

      if (this.model.includes(option.value)) {
        newValue = this.model.filter((value) => value !== option.value);
      } else {
        newValue = [...this.model, option.value];
      }

      this.model = newValue;
    },

    toggleHighlightedOption(event) {
      if (this.isShowing && this.highlightedOption) {
        this.toggleOption(event, this.highlightedOption);
      }
    },

    handleEnterKeyPress(event) {
      event.preventDefault();

      if (this.isShowing && this.highlightedOption) {
        this.toggleHighlightedOption(event);
      } else {
        this.toggleOptions(event);
      }
    },

    handleMouseHoverOption(event, option) {
      const mouseMoved = event.movementX !== 0 || event.movementY !== 0;

      // Only update highlighted option if mouse actually moved. If it didn't
      // move the event is probably fired because a new option was moved
      // underneath the cursor by stepping through options with the arrow keys.
      if (!option.group && mouseMoved) {
        this.highlightedOption = option;
      }
    },

    handleFindOption(event) {
      if (event.key.length === 1) {
        this.query += event.key;
      }
    },

    toggleOptions(event) {
      if (this.isShowing) {
        this.closeOptions(event);
      } else {
        this.$refs.tippy.show();
      }
    },

    closeOptions(event) {
      if (this.isShowing) {
        event.preventDefault();
        event.stopPropagation();

        this.$refs.tippy.hide();
        this.highlightedOption = null;
      }
    },

    toggleOptionsAndFocusTrigger(event) {
      this.$refs.trigger.focus();
      this.toggleOptions(event);
      event.preventDefault();
    },

    highlightNextOption() {
      if (!this.isShowing) {
        return;
      }

      if (!this.highlightedOption) {
        [this.highlightedOption] = this.justOptions;

        this.scrollToHighlightedOption();
      } else {
        const index = this.justOptions.indexOf(this.highlightedOption);

        if (index < this.justOptions.length - 1) {
          this.highlightedOption = this.justOptions[index + 1];

          this.scrollToHighlightedOption();
        }
      }
    },

    highlightPreviousOption() {
      if (!this.isShowing) {
        return;
      }

      if (this.highlightedOption) {
        const index = this.justOptions.indexOf(this.highlightedOption);

        if (index > 0) {
          this.highlightedOption = this.justOptions[index - 1];
        }
      } else {
        this.highlightedOption = this.justOptions[this.justOptions.length - 1];
      }

      this.scrollToHighlightedOption();
    },

    updateValidity() {
      if (!this.$refs.input) {
        return;
      }

      if (this.required && !this.selected) {
        this.$refs.input.setCustomValidity("Vælg et punkt på listen.");
      } else {
        this.$refs.input.setCustomValidity("");
      }
    },

    setDefaultHighlightedOption() {
      if (this.selected && !this.highlightedOption) {
        this.highlightedOption = this.selected;
      }
    },

    getHighlightedElement() {
      if (!this.highlightedOption) {
        return null;
      }

      return this.getOptionElement(this.highlightedOption);
    },

    getOptionElement(option) {
      return this.$refs.option?.find((optionElement) => optionElement.id === `${this.idOrName}Option${option.value}`);
    },

    scrollToHighlightedOption() {
      if (this.isShowing && this.highlightedOption) {
        requestAnimationFrame(() => {
          this.getHighlightedElement()?.scrollIntoView({
            block: "nearest",
          });
        });
      }
    },

    toggleSelectAll() {
      let newValue;

      if (this.allSelected) {
        newValue = [];
      } else {
        newValue = this.justOptions.map((option) => option.value);
      }

      this.model = newValue;
    },
  },
};
</script>
