<template>
  <div :class="['my-4', $attrs.class]">
    <Select v-bind="{ ...$attrs, ...$props, options: computedOptions }" v-model="model" />
  </div>
</template>

<script>
import Select from "~/components/inputs/Select";
import { withModel } from "~/mixins/withModel";

export default {
  name: "BaseSelect",

  components: { Select },

  mixins: [withModel],
  inheritAttrs: false,

  props: {
    ...Select.props,
    labelKey: {
      type: String,
      default: "label",
    },

    valueKey: {
      type: String,
      default: "value",
    },
  },

  computed: {
    computedOptions() {
      if (!this.options) {
        return [];
      }

      return this.options.map((option) => ({
        label: option[this.labelKey],
        value: option[this.valueKey],
      }));
    },
  },
};
</script>
