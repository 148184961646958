import { applyProperties } from "~/utils/vmHelpers";

/**
 * Get all GraphQL error messages for errors without extensions.
 * @param {*} error
 * @returns Array with all error messages
 */
export function getErrorMessages(error) {
  const { graphQLErrors } = error;

  return graphQLErrors?.filter((graphQLError) => !graphQLError.extensions).map((graphQLError) => graphQLError.message) || [];
}

/**
 * Get all validation errors on a GraphQL response as flat object with array values.
 * @param {*} error
 * @returns {*} Object containing field names as keys and the values their validation errors as arrays.
 */
export const getValidationErrors = (error) => {
  const { graphQLErrors } = error;
  const errors = {};

  if (graphQLErrors) {
    graphQLErrors.forEach((graphQLError) => {
      const { validationErrors } = graphQLError.extensions || {};

      if (validationErrors) {
        Object.keys(validationErrors).forEach((key) => {
          errors[key] = errors[key] || [];
          errors[key] = errors[key].concat(validationErrors[key]);
        });
      }
    });
  }

  return errors;
};

/**
 * Applies errors from a GraphQL error.
 * @param target Where to put the errors
 * @param error GraphQL error
 */
export const applyValidationErrorsFromGraphQL = (target, error) => {
  const errors = getValidationErrors(error);
  const hasErrors = Object.keys(errors).length > 0;

  if (hasErrors) {
    applyProperties(target, getValidationErrors(error));

    return true;
  }
  return false;
};

export function getGraphQLErrorsByCode(error, code) {
  const { graphQLErrors } = error;

  return graphQLErrors?.filter((graphQLError) => graphQLError.extensions?.code === code) || [];
}
