<template>
  <div class="my-4">
    <PhoneNumberInput v-bind="{ ...$attrs, ...$props }" v-model="model" />
  </div>
</template>

<script>
import PhoneNumberInput from "~/components/inputs/PhoneNumberInput";
import { withModel } from "~/mixins/withModel";

export default {
  components: { PhoneNumberInput },

  mixins: [withModel],
  inheritAttrs: false,

  props: PhoneNumberInput.props,
};
</script>
