<template>
  <div class="my-4">
    <TimeInput v-bind="{ ...$attrs, ...$props }" v-model="model" />
  </div>
</template>

<script>
import TimeInput from "~/components/inputs/TimeInput";
import { withModel } from "~/mixins/withModel";

export default {
  components: { TimeInput },

  mixins: [withModel],
  inheritAttrs: false,

  props: TimeInput.props,
};
</script>
