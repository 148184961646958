import { applyValidationErrorsFromGraphQL, getErrorMessages } from "~/utils/gqlHelpers";
import { honeybadger } from "~/utils/honeybadgerConfig";
import { toast } from "~/utils/toast";

export function handleError(error, validationErrorsTargetOrTitle = {}, titleMaybe = "Fejl - prøv igen eller kontakt supporten") {
  const isNetworkError = Boolean(error.networkError);
  const errorMessages = getErrorMessages(error);
  let title = titleMaybe;
  let validationErrorsTarget;
  let hasValidationErrors;

  if (typeof validationErrorsTargetOrTitle === "string") {
    title = validationErrorsTargetOrTitle;
  } else {
    validationErrorsTarget = validationErrorsTargetOrTitle;
  }

  if (validationErrorsTarget !== undefined) {
    applyValidationErrorsFromGraphQL(validationErrorsTarget, error);

    hasValidationErrors = Object.keys(validationErrorsTarget).length > 0;
  }

  if (isNetworkError) {
    title = "Netværksfejl - tjek forbindelsen og prøv igen eller kontakt supporten";
  }

  if (hasValidationErrors) {
    Object.keys(validationErrorsTarget).forEach((key) => {
      const errors = validationErrorsTarget[key];

      if (errors.length) {
        toast.error(title, errors.join(" "));
      }
    });
  } else if (errorMessages.length) {
    errorMessages.forEach((errorMessage) => toast.error(errorMessage));
  } else {
    toast.error(title);
  }

  if (!hasValidationErrors && !isNetworkError) {
    honeybadger.notify(error);
  }

  return {
    isNetworkError,
    hasValidationErrors,
    validationErrors: validationErrorsTarget,
  };
}
