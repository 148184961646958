<script setup>
import { TabsContent } from "radix-vue";
import { inject, onMounted, watch } from "vue";
import { registerPanelInjectKey } from "~/components/base/Tabs/BaseTabs";
import { kebabCase } from "lodash";

const props = defineProps({
  name: { type: String, required: true },
  /**
   * Use frame when you want to add a border around the content.
   */
  framed: { type: Boolean, default: false },
});

const registerPanel = inject(registerPanelInjectKey);
const id = kebabCase(props.name);

onMounted(() => {
  registerPanel({ id, label: props.name });
});

watch(
  () => props.name,
  (name) => {
    registerPanel({ id, label: name });
  }
);
</script>

<template>
  <TabsContent :value="id">
    <div :class="[framed && 'rounded-md border p-4']">
      <slot />
    </div>
  </TabsContent>
</template>
