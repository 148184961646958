import { defineAsyncComponent } from "vue";
import { closeModal, openModal } from "~/utils/modals";

const imports = import.meta.glob("~/components/modals/*");

export const modals = {
  install(app) {
    Object.entries(imports).forEach(([path, component]) => {
      app.component(path.match(/(\w+)\.vue/)[1], defineAsyncComponent(component));
    });

    app.config.globalProperties.$openModal = openModal;
    app.config.globalProperties.$closeModal = closeModal;
  },
};
