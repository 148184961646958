import formatDate from "~/filters/formatDate";
import formatDateWithDay from "~/filters/formatDateWithDay";
import formatMonth from "~/filters/formatMonth";
import formatDateTime from "~/filters/formatDateTime";
import formatTime from "~/filters/formatTime";
import formatNumber from "~/filters/formatNumber";
import formatPhoneNumber from "~/filters/formatPhoneNumber";
import formatCpr from "~/filters/formatCpr";
import formatText from "~/filters/formatText";
import formatMinutes from "~/filters/formatMinutes";
import formatTimeago from "~/filters/formatTimeago";

export const filters = {
  install(app) {
    app.config.globalProperties.$filters = {
      formatDate,
      formatDateWithDay,
      formatMonth,
      formatDateTime,
      formatCpr,
      formatNumber,
      formatPhoneNumber,
      formatText,
      formatTime,
      formatMinutes,
      formatTimeago,
    };
  },
};
