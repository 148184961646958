import gql from "graphql-tag";

export const hasStudentPageAccessQuery = gql`
  query hasStudentPageAccessQuery($id: ID!) {
    student(id: $id) {
      id
      hasStudentPageAccess
    }
  }
`;
