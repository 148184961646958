<template>
  <div>
    <div ref="trigger" class="contents">
      <slot name="trigger" v-bind="{ showing, controlId }" />
    </div>

    <div ref="content" class="hidden">
      <div class="z-10 overflow-hidden rounded-md border bg-white shadow-lg focus:outline-none">
        <div ref="scrollParent" class="overflow-auto overscroll-contain" :style="{ maxHeight: _maxHeight + 'px' }" data-tippy-content @mousedown="handleMouseDown">
          <slot name="content" v-bind="{ showing, reposition, controlId }" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tippy from "tippy.js";
import mergeWith from "lodash/mergeWith";
import maxSize from "popper-max-size-modifier";
import { nanoid } from "nanoid";

export default {
  provide() {
    return {
      hide: this.hide,
      reposition: this.reposition,
    };
  },

  props: {
    options: { type: Object, default: () => ({}) },
    largeShadow: Boolean,
    arrowBgColor: {
      type: String,
      default: "white",
    },

    maxHeight: { type: Number, default: null },
    keepVisibleElement: HTMLElement,
    preventBlurOnMouseDown: Boolean,
  },

  emits: ["mount", "hidden", "show", "hide"],

  data() {
    return {
      tippy: null,
      maxAvailableHeight: null,
      showing: false,
      controlId: `tippy-${nanoid()}`,
    };
  },

  computed: {
    _maxHeight() {
      if (this.maxHeight && this.maxHeight < this.maxAvailableHeight) {
        return this.maxHeight;
      }

      return this.maxAvailableHeight;
    },
  },

  beforeUnmount() {
    if (this.tippy) {
      this.tippy.destroy();
    }
  },

  mounted() {
    const vm = this;
    const { trigger, content } = vm.$refs;

    const defaultOptions = {
      arrow: `<div class="tippy__arrow text-${this.arrowBgColor}"></div>`,
      maxWidth: 400,
      content: content.firstElementChild,
      allowHTML: true,
      trigger: "mouseenter",
      animation: "popover",
      duration: [200, 150],
      interactive: true,
      placement: "right",
      hideOnClick: false,
      onMount: this.onTippyMount,
      onHidden: this.onTippyHidden,
      onShow: this.onTippyShow,
      onHide: this.onTippyHide,
      appendTo: this.options.appendTo || (() => trigger.closest("#focus-trap, [data-tippy-content]") || document.body),

      zIndex: 100,
      popperOptions: {
        modifiers: [
          maxSize,
          {
            enabled: true,
            name: "applyMaxSize",
            phase: "beforeWrite",
            requires: ["maxSize"],
            fn({ state }) {
              vm.maxAvailableHeight = state.modifiersData.maxSize.height - 8;
            },
          },
        ],
      },
    };

    this.tippy = tippy(
      trigger.firstElementChild,
      mergeWith(defaultOptions, this.options, (objValue, srcValue) => {
        if (Array.isArray(objValue)) {
          return objValue.concat(srcValue);
        }
      })
    );
  },

  methods: {
    handleMouseDown(event) {
      if (this.preventBlurOnMouseDown) {
        event.preventDefault();
      }
    },

    onTippyMount() {
      this.$emit("mount");
    },

    onTippyHidden() {
      this.$emit("hidden");
    },

    onTippyShow() {
      // Hide all other tippy poppers unless it contains this tippy's trigger
      // see https://github.com/atomiks/tippyjs/issues/296
      document.querySelectorAll("[data-tippy-root]").forEach((popper) => {
        if (!popper.contains(this.$refs.trigger)) {
          popper._tippy.hide();
        }
      });

      this.showing = true;
      this.$emit("show");

      document.addEventListener("click", this.handleClick);
    },

    onTippyHide() {
      this.showing = false;
      this.$emit("hide");

      document.removeEventListener("click", this.handleClick);
    },

    handleClick(event) {
      const { popper, reference } = this.tippy;
      const { target } = event;

      if (this.showing && reference !== target && !reference.contains(target) && popper !== target && !popper.contains(target)) {
        this.hide();
      }
    },

    reposition() {
      this.tippy.popperInstance?.update();
    },

    hide() {
      this.tippy.hide();
    },

    show() {
      this.tippy.show();
    },

    scrollTo(top) {
      requestAnimationFrame(() => {
        this.$refs.scrollParent.scrollTo({ top });
      });
    },
  },
};
</script>

<style lang="scss">
.tippy__arrow {
  width: 16px;
  height: 16px;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  border-bottom: 8px solid #e5e7eb;
  position: relative;
  // background: red;

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 2px);
    width: 10px;
    height: 10px;
    // background: blue;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    border-bottom: 7px solid currentColor;
  }
  // position: absolute;
  // display: flex;
  // align-items: center;
  // justify-content: center;

  // &:before {
  //   content: "";
  //   display: block;
  //   width: 0;
  //   height: 0;
  //   border-right: 0.5rem solid transparent;
  //   border-left: 0.5rem solid transparent;
  //   border-bottom: 0.5rem solid currentColor;
  // }

  // &:after {
  //   content: "";
  //   display: block;
  //   border-top: 1px solid #e4e4e4;
  //   border-left: 1px solid #e4e4e4;
  //   width: 0.7071rem;
  //   height: 0.7071rem;
  //   transform-origin: 50% 50%;
  //   transform: rotate(45deg);
  //   position: absolute;
  //   top: 4px;
  // }

  [data-placement^="top"] & {
    transform: rotate(180deg);
    top: 10px;
  }

  [data-placement^="right"] & {
    transform: rotate(-90deg);
    right: 15px;
  }
  [data-placement^="bottom"] & {
    top: calc(-100% + 1px);
  }

  [data-placement^="left"] & {
    transform: rotate(90deg);
    left: 10px;
  }
}

.tippy-box.anim-scale-in {
  &[data-placement^="right-end"] {
    transform-origin: bottom left;
  }
}
</style>
