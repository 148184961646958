<template>
  <button
    type="button"
    :disabled="disabled || loading"
    class="inline-flex items-center rounded p-1 transition-colors duration-150 ease-in-out hover:bg-black hover:bg-opacity-5 disabled:cursor-default disabled:opacity-50 disabled:hover:bg-transparent"
    :class="{
      'ring-2 ring-blue-600': active,
      '-my-1': tighten,
    }"
  >
    <BaseSpinner v-if="loading" size="md" />
    <svgicon
      v-if="!loading"
      v-tooltip="title"
      :name="icon"
      :fill="useFill"
      :class="[size === 'xs' && 'h-3 w-3', size === 'sm' && 'h-4 w-4', size === 'md' && 'h-5 w-5', size === 'lg' && 'h-6 w-6']"
    />
  </button>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "md",
      validator(value) {
        return ["xs", "sm", "md", "lg"].includes(value);
      },
    },

    icon: String,
    title: String,
    loading: Boolean,
    fill: Boolean,
    active: Boolean,
    tighten: Boolean,
    disabled: Boolean,
  },

  computed: {
    useFill() {
      return this.fill || this.icon.includes("solid");
    },
  },
};
</script>
