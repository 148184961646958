import Notifications from "@kyvg/vue3-notification";
import { toast } from "~/utils/toast";

export const toasts = {
  install(app) {
    app.use(Notifications);

    app.config.globalProperties.$toast = {
      info: toast.info,
      success: toast.success,
      error: toast.error,
      clear: toast.clear,
      async: toast.async,
    };
  },
};
