/**
 * Formats a minute amount to h:mm format.
 *
 * @param {*} minutes The minutes
 */
export default (minutes) => {
  const h = Math.floor(minutes / 60);
  const m = Math.round(minutes % 60);
  const hh = h.toString();
  const mm = m.toString().padStart(2, "0");

  return `${hh}:${mm}`;
};
