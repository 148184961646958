import { dayjs } from "~/utils/dayjs";

/**
 * Format a date to DD/MM/YYYY
 * @typedef {import('dayjs').Dayjs} Dayjs
 * @param {Dayjs | Date | string | undefined | null} date the date to format
 * @param {string=} format format of the given date
 * @returns {string}
 */
export default (date, format) => (date ? dayjs(date, format).format("DD/MM/YYYY") : "");
