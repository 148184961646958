<template>
  <div class="inline-flex max-w-full cursor-default items-baseline rounded p-1 text-xs font-medium leading-none" :class="`bg-${color}-200 text-${color}-900`">
    <div class="max-w-full overflow-hidden truncate">
      <slot />
    </div>
    <slot name="right" />
  </div>
</template>

<script>
import { colors } from "~/constants/options";

export default {
  name: "Tag",

  props: {
    color: {
      type: String,
      default: "gray",
      validator(value) {
        return colors.includes(value);
      },
    },
  },
};
</script>
