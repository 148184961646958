/**
 * Set all properties on an object to undefined.
 * @param {*} object
 * @returns {boolean} Properties removed.
 */
export const removeAllProperties = (object) => {
  const keys = Object.keys(object);

  keys.forEach((key) => (object[key] = undefined));

  return keys.length > 0;
};

/**
 * Adds properties from source to object. Will overwrite existing properties on object.
 * @param {*} object
 * @param {*} source
 * @returns {boolean} Properties changed.
 */
export const applyProperties = (object, source) => {
  const keys = Object.keys(source);

  keys.forEach((key) => (object[key] = source[key]));

  return keys.length > 0;
};
