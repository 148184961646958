function getDefaultState() {
  return {
    student: {
      selectedStay: undefined,
      stays: [],
      notes: [],
      searchedStays: [],
      taxCards: [],
      studentFiles: [],
      contactPeople: [],
      kuiContacts: [],
      studentEconomies: [],
    },
  };
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    searchedStays(state) {
      return state.student.searchedStays;
    },
  },
  mutations: {
    reset(state) {
      Object.assign(state, getDefaultState());
    },
    setProfile(state, payload) {
      Object.assign(state.student, payload);
    },
    addStudentEconomy(state, payload) {
      const index = state.student.studentEconomies.findIndex(({ id }) => id === payload.id);

      if (index > -1) {
        state.student.studentEconomies[index] = payload;
      } else {
        state.student.studentEconomies.push(payload);
      }
    },
    setSearchedStays(state, payload) {
      state.student.searchedStays = payload;
    },
  },
  actions: {
    setProfile(context, payload) {
      context.commit("setProfile", payload);
    },
    addStudentEconomy(context, payload) {
      context.commit("addStudentEconomy", payload);
    },
    setSearchedStays(context, payload) {
      context.commit("setSearchedStays", payload);
    },
  },
};
