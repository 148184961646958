<template>
  <button
    ref="button"
    v-tooltip="title"
    :type="type"
    :disabled="disabled || loading"
    class="inline-flex items-center rounded-md border font-medium transition-colors duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-1 disabled:cursor-default"
    :class="[
      isDefault && 'border-gray-300 bg-white text-gray-700 hover:bg-gray-100 disabled:bg-gray-100 disabled:text-gray-400',
      isPrimary && 'border-blue-700 bg-blue-700 text-white hover:bg-blue-800 disabled:text-blue-400',
      isSecondary && 'border-blue-300 bg-blue-50 text-blue-700 hover:bg-blue-100 disabled:text-blue-400',
      isDanger && 'border-red-700 bg-red-700 text-white hover:bg-red-800 disabled:text-red-400',
      computedSize === 'xs' && 'h-6 px-2 text-xs leading-6',
      computedSize === 'sm' && 'h-7 px-2 text-xs leading-7',
      computedSize === 'md' && 'h-8 px-3 text-sm leading-8',
      computedSize === 'lg' && 'h-10 px-4 text-sm leading-10',
      tighten && '-my-1',
    ]"
  >
    <BaseSpinner v-if="loading" class="mr-2" size="sm" />
    <svgicon v-if="icon && !loading" :name="icon" :fill="icon.includes('solid')" class="mr-1 h-4 w-4" />
    <slot />
  </button>
</template>

<script>
import { withRenderContextMixin } from "~/mixins/withRenderContextMixin";

export default {
  mixins: [withRenderContextMixin()],

  props: {
    type: {
      type: String,
      default: "button",
    },

    size: {
      type: String,
      validator(value) {
        return ["xs", "sm", "md", "lg"].includes(value);
      },
    },

    icon: String,
    title: String,
    loading: Boolean,
    disabled: Boolean,
    primary: Boolean,
    secondary: Boolean,
    danger: Boolean,
    tighten: Boolean,
  },

  computed: {
    computedSize() {
      if (this.size) {
        return this.size;
      }

      if (this.renderContext.includes("modal")) {
        return "lg";
      }

      return "md";
    },

    isDefault() {
      return !this.primary && !this.secondary && !this.danger;
    },

    isPrimary() {
      return this.primary;
    },

    isSecondary() {
      return this.secondary;
    },

    isDanger() {
      return this.danger;
    },
  },

  methods: {
    focus() {
      return this.$refs.button.focus();
    },
  },
};
</script>
