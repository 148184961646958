/**
 * Formats minutes as hh:mm timestring.
 * @param {number} minutes
 * @returns {string}
 */
export function minutesToTimestring(input, padHours = true) {
  const hours = Math.floor(input / 60).toString();
  const minutes = input % 60;
  const hoursString = padHours ? hours.padStart(2, "0") : hours;
  const minutesString = minutes.toString().padStart(2, "0");

  return `${hoursString}:${minutesString}`;
}

/**
 * Formats minutes as `hh ${timer}, mm ${minutes}`.
 * @param {number} minutes
 * @returns {string}
 */
export function minutesToLongString(input) {
  const hours = Math.floor(input / 60);
  const minutes = Math.round(input % 60);
  const hoursLabel = hours === 1 ? "time" : "timer";
  const minutesLabel = minutes === 1 ? "minut" : "minutter";

  if (hours === 0) {
    return `${minutes} ${minutesLabel}`;
  }

  if (minutes === 0) {
    return `${hours} ${hoursLabel}`;
  }

  return `${hours} ${hoursLabel}, ${minutes} ${minutesLabel}`;
}

/**
 * Takes a timestring hh:mm and converts it to minutes.
 * @param {*} input
 * @returns {number}
 */
export function timestringToMinutes(input) {
  const [h, m] = input.split(":");
  const minutes = h * 60 + parseInt(m, 10);

  return minutes;
}

/**
 * Takes a timestring hh:mm and returns a tuple with hours and minutes.
 * @param {string} input
 * @returns
 */
export function timestringToHoursAndMinutes(input) {
  const [hours, minutes] = input.split(":");

  return [parseInt(hours, 10), parseInt(minutes, 10)];
}
