import { ApolloClient, InMemoryCache } from "@apollo/client/core";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { createApolloProvider } from "@vue/apollo-option";
import { logErrorMessages } from "@vue/apollo-util";
import { ApolloLink } from "@apollo/client";
import { ErrorLink } from "apollo-link-error";
import showUpdateMessage from "~/utils/showUpdateMessage";
import { API_URL, SESSION_TOKEN } from "~/constants/settings";
import { dayjs } from "~/utils/dayjs";
import { router } from "~/router";

const httpLink = new BatchHttpLink({
  batchMax: 10,
  batchInterval: 10,
  uri: `${API_URL}/graphql`,
  credentials: "include",
  headers: { batch: "true" },
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const jwtToken = localStorage.getItem(SESSION_TOKEN);
  const headers = { Authorization: `Bearer ${jwtToken}` };
  const institutionNumber = window.location.pathname.match(/^\/(\d+)/)?.[1];

  if (institutionNumber) {
    headers.Institution = institutionNumber;
  }

  if (jwtToken) {
    operation.setContext({ headers });
  }
  return forward(operation);
});

/**
 * Formats all Date objects in the query variables to ISO 8601 strings and keeps the timezone.
 * If we let apollo client handle this, the date is converted to UTC and the date could be off.
 * The Ruby GraphQL will just strip the timezone and use the date as is.
 *
 * Example using new Date('2024-01-10'):
 * Apollo Client: 2024-01-09T23:00:00.000Z
 * This middleware: 2024-01-10T00:00:00+01:00
 */
const dateFormattingMiddleware = new ApolloLink((operation, forward) => {
  Object.entries(operation.variables).forEach(([key, value]) => {
    if (value instanceof Date) {
      operation.variables[key] = dayjs(value).format();
    }
  });

  return forward(operation);
});

const versionAfterware = new ApolloLink((operation, forward) =>
  forward(operation).map((response) => {
    const context = operation.getContext();
    const version = context.response.headers.get("Frontend-Version");

    if (
      version &&
      __FRONTEND_VERSION__ && // eslint-disable-line no-undef
      ![__FRONTEND_VERSION__, "development"].includes(version) // eslint-disable-line no-undef
    ) {
      showUpdateMessage();
    }

    return response;
  })
);

const errorLink = new ErrorLink((error) => {
  const isGraphQLError = Boolean(error.graphQLErrors && error.graphQLErrors.length);
  const isNetworkError = Boolean(error.networkError);
  const isValidationErrors = isGraphQLError && error.graphQLErrors.some((e) => e.extensions?.code === "VALIDATION_ERROR");

  if (isNetworkError && error.networkError.statusCode === 404) {
    return router.push({ path: "/not-found" });
  }

  if (!import.meta.env.PROD || (import.meta.env.PROD && !isNetworkError && !isValidationErrors)) {
    logErrorMessages(error);
  }
});

const cache = new InMemoryCache({
  possibleTypes: {
    EducationModuleInterface: ["Graduation", "VocationalTraining", "WorkExperience", "Workshop", "Course", "OptionalSubject"],
  },
});

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([versionAfterware, dateFormattingMiddleware, authMiddleware, errorLink, httpLink]),
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
    query: {
      fetchPolicy: "network-only",
    },
  },
});

export const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
});
