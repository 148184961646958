<template>
  <svg class="h-3 w-3" viewBox="0 0 30 30" :class="`text-${color}-500 inline rounded-sm`">
    <rect width="30" height="30" fill="currentColor" />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "gray",
    },
  },
};
</script>
