<script setup>
import { TabsContent } from "radix-vue";
import { inject, onMounted } from "vue";
import { registerPanelInjectKey } from "~/components/base/Card/BaseCardWithTabs";

const props = defineProps({
  name: { type: String, required: true },
});

const registerPanel = inject(registerPanelInjectKey);

onMounted(() => {
  registerPanel(props.name);
});
</script>

<template>
  <TabsContent :value="name">
    <slot />
  </TabsContent>
</template>
