<script setup>
import { cva } from "class-variance-authority";
import { cn } from "~/utils/cn";

const variants = cva("flex w-full flex-wrap items-center", {
  variants: {
    look: {
      pills: "space-x-2",
      underline: "-mb-px space-x-6",
    },
  },
  defaultVariants: {
    look: "pills",
  },
});

defineProps({
  look: { type: String, default: "pills", validator: (v) => ["pills", "underline"].includes(v) },
});
</script>

<template>
  <div :class="['mb-2 w-full', look === 'underline' && 'border-b border-gray-200']">
    <div :class="cn(variants({ look }))">
      <slot />
    </div>
  </div>
</template>
