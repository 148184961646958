import tippy from "tippy.js";

function createTooltip(element, value) {
  if (!element.hasAttribute("aria-label")) {
    element.setAttribute("aria-label", value);
  }

  tippy(element, {
    content: value,
    theme: "custom",
    arrow: '<div class="tooltip-arrow"></div>',
    trigger: "mouseenter focus",
    delay: [500, 0],
    animation: "tooltip",
    duration: 150,
  });
}

export const tooltip = {
  mounted(element, binding) {
    const { value } = binding;

    if (!value) {
      return;
    }

    createTooltip(element, value);
  },

  updated(element, binding) {
    const { value, oldValue } = binding;

    if (!value) {
      if (oldValue) {
        if (element.getAttribute("aria-label") === oldValue) {
          element.removeAttribute("aria-label");
        }
        element._tippy.destroy();
      }
    } else if (value !== oldValue) {
      if (element._tippy) {
        element._tippy.setContent(value);
      } else {
        createTooltip(element, value);
      }
    }
  },
};
